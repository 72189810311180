<template>
    <div class="layout-account-info">
        <div class="account-handle-box" v-if="displayStatus === 1">
            <h1 class="account-title">{{ $t('userCenter.emailAndPassword') }}</h1>
            <div class="box-input-account">
                <font class="country">{{ $t('login.account') }}</font>
                <span class="name">{{ userAccount }}</span>
                <a href="javascript:" @click="displayStatus = 2">{{ $t('userCenter.change') }}</a>
            </div>
            <div class="box-input-account">
                <font class="country">{{ $t('login.password') }}</font>
                <div class="password">
                    <i class="icon" :key="key" v-for="key in 11"></i>
                </div>
                <a href="javascript:" @click="displayStatus = 3">{{ $t('userCenter.change') }}</a>
            </div>
        </div>
        <RestEmail @on-account-subassembly_status="onAccountSubassemblyStatus" v-if="displayStatus === 2"/>
        <RestPassword @on-account-subassembly_status="onAccountSubassemblyStatus" v-if="displayStatus === 3"/>
    </div>
</template>
<script>
import AccountInfo from './accountInfo'
export default AccountInfo
</script>
<style lang="less">
@import "accountInfo";
</style>
