<template>
    <div class="layout-user-center">

        <!--s: Title-->
        <div class="layout-user-title">{{ $t('header.account') }}</div>
        <!--e: Title-->

        <!--s: User Menu-->
        <div class="layout-user-left">
            <div class="layout-user-left-ul">
                <ul>
                    <li :key="key" v-for="(menu,key) in userMenu">
                        <a href="javascript:" :class="selectMenu === menu.label? 'active':''" @click="onLinks(menu.label)">{{ menu.name }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <!--e: User Menu-->

        <div class="layout-user-content">
            <div class="user-box">
                <AccountInfo v-if="selectMenu === linksName.ACCOUNT_INFO"/>
                <Information v-if="selectMenu === linksName.SEARCH_REMIND_SHOPPING_LIST"/>
                <Preferences v-if="selectMenu === linksName.USER_PREFERENCES"/>
            </div>
        </div>

    </div>
</template>
<script>
import UserCenter from './userCenter'
export default UserCenter
</script>
<style lang="less">
@import "userCenter";
</style>
