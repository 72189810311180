<template>
  <div class="layout-account-info">
    <div class="account-handle-box">
      <h1 class="account-title">{{ $t("userCenter.emailAndPassword") }}</h1>
      <div class="box-input-account">
        <font class="country">{{ $t("login.account") }}</font>
        <span class="name">{{ account }}</span>
      </div>
      <div class="box-input">
        <input
          v-model="password"
          :type="passwordVisible ? 'text' : 'password'"
          @focus="onFocus('password')"
          @blur="onBlur('password')"
          :placeholder="passwordPlaceholder"
          class="input"
        />
        <a href="javascript:" @click="onPasswordShow">{{
          passwordVisible ? $t("login.hide") : $t("login.show")
        }}</a>
      </div>
      <div class="box-input">
        <input
          v-model="newAccount"
          type="text"
          @focus="onFocus('newAccount')"
          @blur="onBlur('newAccount')"
          :placeholder="newAccountPlaceholder"
          class="input"
        />
      </div>
      <div class="box-input">
        <input
          v-model="newPassword"
          :type="newPasswordVisible ? 'text' : 'password'"
          @focus="onFocus('newPassword')"
          @blur="onBlur('newPassword')"
          :placeholder="newPasswordPlaceholder"
          class="input"
        />
        <a href="javascript:" class="title" @click="onNewPasswordShow">{{
          newPasswordVisible ? $t("login.hide") : $t("login.show")
        }}</a>
      </div>
      <div class="box-btn">
        <a href="javascript:" @click="onRestPasswordEvent">{{
          $t("userCenter.cancel")
        }}</a>
        <Button
          shape="circle"
          :loading="loadingStatus"
          @click="onRestAccountCheck"
        >
          <span v-if="!loadingStatus">{{ $t("userCenter.save") }}</span>
          <span v-else>{{ $t("userCenter.save") }}</span>
        </Button>
      </div>
      <div class="input-tips" v-show="tipsVisible">{{ tipsValue }}</div>
    </div>
  </div>
</template>
<script>
import RestEmail from "./restEmail";
export default RestEmail;
</script>
<style lang="less">
@import "restEmail";
</style>
