/**
 *  账号信息
 * @type {{}}
 */
import RestEmail from "@/components/AccountInfo/RestEmail/restEmail.vue";
import RestPassword from "@/components/AccountInfo/RestPassword/restPassword.vue";
import { mapState } from "vuex";
const AccountInfo = {
    name:'AccountInfo',
    components:{ RestEmail,RestPassword },
    computed: {
        ...mapState({
            userAccount: state => state.userCenter.userAccount,  //获取状态管理用户名称
        }),
    },
    data() {
        return {
            displayStatus:1, //组件显示状态
        }
    },
    methods:{
        /**
         * 更改登录、注册组件状态
         */
        onAccountSubassemblyStatus(){
            this.displayStatus = 1
        }

    }
}
export default AccountInfo
