/**
 *  账号信息
 * @type {{}}
 */
import Vue from "vue";
import { Button } from "iview";
Vue.component("Button", Button);
import { validateEmail } from "@/utils/tools";
import { mapState } from "vuex";
import { userRestAccountService } from "@/service/user-service";
import Cookies from "js-cookie";
const RestEmail = {
  name: "RestEmail",
  computed: {
    ...mapState({
      userAccount: (state) => state.userCenter.userAccount //获取状态管理用户名称
    })
  },
  data() {
    return {
      loadingStatus: false,
      passwordVisible: false,
      tipsVisible: false,
      newPasswordVisible: false,
      tipsValue: "",
      account: "",
      newAccount: "",
      password: "",
      newPassword: "",
      newAccountPlaceholder: this.$t("login.newAccount"),
      passwordPlaceholder: this.$t("login.password"),
      newPasswordPlaceholder: this.$t("login.newPassword")
    };
  },
  created() {
    this.account = this.userAccount;
  },
  mounted() {
    window.addEventListener("keydown", this.onKeyDown);
  },
  methods: {
    onFocus(name) {
      if (name === "newAccount") {
        this.newAccountPlaceholder = "";
      }
      if (name === "password") {
        this.passwordPlaceholder = "";
      }
      if (name === "newPassword") {
        this.newPasswordPlaceholder = "";
      }
    },
    onBlur(name) {
      if (name === "newAccount") {
        this.newAccountPlaceholder = this.$t("login.newAccount");
      }
      if (name === "password") {
        this.passwordPlaceholder = this.$t("login.password");
      }
      if (name === "newPassword") {
        this.newPasswordPlaceholder = this.$t("login.newPassword");
      }
    },
    /**
     * 密码可显示
     */
    onPasswordShow() {
      this.passwordVisible
        ? (this.passwordVisible = false)
        : (this.passwordVisible = true);
    },
    /**
     * 密码可显示
     */
    onNewPasswordShow() {
      this.newPasswordVisible
        ? (this.newPasswordVisible = false)
        : (this.newPasswordVisible = true);
    },
    /**
     * 获取键盘Enter值 调用修改邮箱
     * @param e
     */
    onKeyDown(e) {
      if (e.keyCode === 13) {
        this.onRestAccountCheck();
      }
    },
    /**
     *  校验方法 通过之后调用修改邮箱
     *  @param account && password
     */
    onRestAccountCheck() {
      if (!validateEmail(this.account)) {
        this.tipsVisible = true;
        this.tipsValue = this.$t("login.emailTips");
        return;
      }
      if (this.password === "") {
        this.tipsVisible = true;
        this.tipsValue = this.$t("login.keyPasswordTips");
        return;
      }
      if (!validateEmail(this.newAccount)) {
        this.tipsVisible = true;
        this.tipsValue = this.$t("login.newEmailTips");
        return;
      }
      if (this.account === this.newAccount) {
        this.tipsVisible = true;
        this.tipsValue = this.$t("login.resetAccountTips");
        return;
      }
      this.loadingStatus = true;
      this.onRestAccount().then();
    },
    /**
     * 修改邮箱
     * @returns {Promise<void>}
     */
    async onRestAccount() {
      try {
        let params = {
          oldEmail: this.account,
          newEmail: this.newAccount,
          oldPass: this.password,
          newPass: this.newPassword
        };
        const { code } = await userRestAccountService(params);
        if (code === 1) {
          Cookies.remove("token");
          this.$router.push("/login");
          location.reload();
        }
        if (code === 1001) {
          this.tipsVisible = true;
          this.tipsValue = this.$t("login.passwordInvalidTips");
        }
        if (code === 1002) {
          this.tipsVisible = true;
          this.tipsValue = this.$t("login.accountTips");
        }
        if (code === 1003) {
          this.tipsVisible = true;
          this.tipsValue = this.$t("login.registeredPrompt");
        }
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        console.log(error);
      }
    },
    /**
     * 发送修改邮箱、密码组件状态事件
     */
    onRestPasswordEvent() {
      this.$emit("on-account-subassembly_status");
    }
  },
  destroyed() {
    window.removeEventListener("keydown", this.onKeyDown, false);
  }
};
export default RestEmail;
